import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Router } from '@angular/router';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Badge } from '@capawesome/capacitor-badge';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Browser } from '@capacitor/browser';
import { AnalyticsService } from './analytics.service';

@Injectable({
  providedIn: 'root'
})
export class PushService {
  public hasPermission: boolean = false;
  public token: string = "";
  public message: any;

  constructor(
    private platform: Platform,
    public fireMessaging: AngularFireMessaging,
    //public fbx: FirebaseX,
    public router: Router,
    public browser: InAppBrowser,
    public analyticsService: AnalyticsService
  ) {}

  public async init() {
      //this.webPermission();
      if(await this.checkPermission())
      {
        await Badge.clear();
        await Badge.set({count: 0});
      }
      console.log("PUSH STATUS: " + (await FirebaseMessaging.checkPermissions()).receive)
      if(await this.checkPermission())
      {
        await this.initPush();
      }
  }

  public async initPush()
  {
    if(this.platform.is("capacitor"))
    {
      this.token = (await FirebaseMessaging.getToken()).token;
      localStorage.setItem("pushToken", this.token);
      await this.onMessageReceived();
      console.log("PUSH TOKEN: " + this.token);
      LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
        console.log('PUSH NOTIFICATION DATA: ', notification);
        if(notification.notification.extra.action == "workout")
        {
          this.router.navigateByUrl("workout");
        }
      });
    }
    else {
      //this.token = (await this.fireMessaging.getToken.toPromise())!;
    }
  }

  public webPermission() {
    this.fireMessaging.requestPermission
      .pipe(mergeMapTo(this.fireMessaging.tokenChanges))
      .subscribe(
        (token) => { this.token = token! },
        (error) => { console.error(error); },
      );
      localStorage.setItem("pushToken", this.token);
  }

  public async requestPermission() {
    if (this.platform.is('capacitor') && (await FirebaseMessaging.checkPermissions()).receive != 'granted') {
      let status = await FirebaseMessaging.requestPermissions();
      await this.initPush();
      await this.analyticsService.trackEvent("Set Push Permission", {status: status.receive});
    }
    else {
      this.webPermission();
    }
  }

  public async checkPermission() {
    return (this.platform.is('capacitor') && (await FirebaseMessaging.checkPermissions()).receive == 'granted');
  }

  public async getToken() {
    return (await FirebaseMessaging.getToken()).token;
  }
  
  private async onMessageReceived() {
    if(this.platform.is("capacitor"))
    {
      await FirebaseMessaging.addListener('notificationReceived', (message) => {
        console.log('PUSH NOTIFICATION DATA: ', message);
      });

      await FirebaseMessaging.addListener('notificationActionPerformed', event => {
        console.log('notificationActionPerformed', { event });
        this.handleAction(event);
      });
    }
    else {
      /*this.fireMessaging.messages.pipe(take(1)).subscribe(
        (message) => this.handleAction(message),
        (error) => console.error("Error receiving message", error)
      );*/
    }
  }

  public async handleAction(message: any)
  {
    if(message.notification.data.url.includes("grammario.de"))
    {
      //this.browser.create(data.url, "_blank", "toolbarposition=top,location=no,fullscreen=no,toolbartranslucent=yes,closebuttoncolor=#000000,footer=no,closebuttoncaption=Fertig,lefttoright=yes,location=yes,presentationstyle=pagesheet,toolbarcolor=#FFFFFF,hidenavigationbuttons=yes,zoom=no");
      await Browser.open({ url: message.notification.data.url,  presentationStyle: "popover"});
    }
    if(message.action == "workout")
    {
      this.router.navigateByUrl("workout", {replaceUrl: true});
    }
  }

  public async onTokenRefresh() {
    if(this.platform.is("capacitor")) {
      await FirebaseMessaging.addListener('tokenReceived', event => {
        this.token = event.token;
        console.log('tokenReceived', { event });
      });
    }
    else {
      /*this.fireMessaging.tokenChanges.subscribe(
        (token) => {
          this.token = token!;
          console.log(`Got a new token ${token}`);
        },
        (error) => console.error("Error refreshing token", error)
      );*/
    }
  }

  /*private async setBadgeNumber(value: number) {
    this.badge.set({count: value});
  }*/

  /*private async clearBadgeNumber() {
    if ('clearAppBadge' in navigator) {
      try {
        await (navigator as any).clearAppBadge();
      } catch (error) {
        console.error("Error clearing badge number", error);
      }
    } else {
      console.warn("clearAppBadge is not supported in this browser.");
    }
  }*/

  public async scheduleLocal(options: any)
  {
    //await this.requestPermission();
    if(this.platform.is("capacitor") && (await (LocalNotifications.checkPermissions())).display == "granted")
    {
      LocalNotifications.schedule(options);
    }
  }
}
