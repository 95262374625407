import { Injectable } from '@angular/core';
import { isDevMode } from '@angular/core';
import { Mixpanel, MixpanelPeople } from '@awesome-cordova-plugins/mixpanel';
import { Platform } from '@ionic/angular';
import { environment } from "../../environments/environment";
//import { Appsflyer } from '@awesome-cordova-plugins/appsflyer/ngx';
//import { Adjust, AdjustConfig, AdjustEvent } from '@awesome-cordova-plugins/adjust/ngx';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { BranchIo } from '@awesome-cordova-plugins/branch-io';
import posthog from 'posthog-js'
import logRocket from 'logrocket';

declare let window: any;
declare let cordova; 

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(
    private platform: Platform,
    //public appsflyer: Appsflyer,
    //public adjust: Adjust,
    public fbAnalytics: AngularFireAnalytics,
    ) { 
  }

  async init()
  {
    if(isDevMode())
    {
      //Disable Firebase Analytics in dev mode
      //
      await this.fbAnalytics.setAnalyticsCollectionEnabled(false);
      await FirebaseAnalytics.setEnabled({ enabled: false });
    }
    if (this.platform.is("capacitor") /*&& !isDevMode()*/)
    {
      if(this.platform.is("ios"))
      {
        window.cordova.exec(suc, err, "idfa", "requestPermission", []);
        function suc(res: any) {
          console.log("Successfully enabled app tracking: " + JSON.stringify(res))
        }
        function err(res: any ) {
          console.log("Error while enabling app tracking: " + JSON.stringify(res))
        }
      }

      //Mixpanel init
      await Mixpanel.init(environment.mixpanel);

      //Branch SDK
      await BranchIo.initSession();
      let firstParams = await BranchIo.getFirstReferringParams()
      console.log("BRANCH FIRST REFERRER" + firstParams);
      //alert("BRANCH FIRST REFERRER" + firstParams);

        //Singular init
        /*let singularConfig = cordova.plugins.SingularCordovaSdk.SingularConfig(environment.singular.key, "secretKey");
        singularConfig.withLogLevel(cordova.plugins.SingularCordovaSdk.SingularLogLevel.SingularLogLevelDebug);
        singularConfig.withFacebookAppId("2304902353021830"); //enable META Install Referrer

        cordova.plugins.SingularCordovaSdk.init(singularConfig);*/
        //this.adjustInit();
        //await this.appsflyer.initSdk(environment.appsflyer);
      //Purchases.collectDeviceIdentifiers();
    }
  }

  public async trackEvent(name: string, properties?: any) 
  {
    if(this.platform.is("capacitor"))
    {
      await Mixpanel.track(name, properties);
    }
    posthog.capture(name, properties);
  }

  public async MPTrackTime(name: string) 
  {
    if(this.platform.is("capacitor"))
    {
      await Mixpanel.timeEvent(name);
    }
  }

  public async setIdentity(uid: string)
  {
    await BranchIo.setIdentity(uid);
    await Mixpanel.identify(uid);
    posthog.identify(uid);
    logRocket.identify(uid);
  }

  public async signOut()
  {
    await BranchIo.logout();
    await Mixpanel.reset();
    posthog.reset();
  }

  public async MPOptOut()
  {
    //ToDo: Opt out from Mixpanel
  }

  public async MPDeleteUser()
  {
    await MixpanelPeople.deleteUser();
  }

  public async MPSetSuperProps(properties: any)
  {
    await Mixpanel.registerSuperProperties(properties);
  }

  public async GASetScreenName(name: string)
  {
    if(this.platform.is("capacitor"))
    {
      return await FirebaseAnalytics.setCurrentScreen({screenName: name});
    }
    else {
      this.fbAnalytics.logEvent("screen_view", {screen_name: name});
    }
  }

  public async GAOptOut()
  {
    return await FirebaseAnalytics.setEnabled({ enabled: false });
  }
  
  //Adjust
  /*public adjustInit()
  {
    if(this.platform.is("ios"))
    {
      const config = new AdjustConfig(environment.adjust.iosToken, environment.adjust.mode);
      this.adjust.create(config);
      config.setLogLevel(environment.adjust.logmode);
    }
    else if(this.platform.is("android")) {
      const config = new AdjustConfig(environment.adjust.androidToken, environment.adjust.mode);
      this.adjust.create(config);
      config.setLogLevel(environment.adjust.logmode);
    }
  }*/

  /*public async setAppsFlyerID() {
    console.log("SETAPPSFLYER ID; ID: " + await this.appsflyer.getAppsFlyerUID());
    return Purchases.setAppsflyerID(await this.appsflyer.getAppsFlyerUID());
  }*/

  /*public async setAdjustID() {
    console.log("SETADJUST ID; ID: " + await this.adjust.getAdid())
    return Purchases.setAdjustID({adjustID: await this.adjust.getAdid()});
  }*/

  public async trackConversion(name: string, value: number) {
    BranchIo.sendBranchEvent(name, {revenue: value});
    /*let event: AdjustEvent = new AdjustEvent(name);
    event.setRevenue(value, "EUR");
    return this.adjust.trackEvent(event);*/
  }

  public async branchTrackEvent(name: string, metadata: any) {
    return await BranchIo.sendBranchEvent(name, metadata);
  }

  public async branchSetReqMetData() {
    return await BranchIo.setRequestMetadata();
  }

  public postHogEvent(name: string, properties?: any)
  {
    posthog.capture(name, properties);
  }

  public postHogSurvey(id: string, selector: any)
  {
    posthog.renderSurvey(id, selector);
  }   
  
  public setRevCatEmail(email: string)
  {
    if(email != undefined || email != "")
    {
      Purchases.setEmail({email});
    }
  }
  
  public async setRevCatCampaignData()
  {
    await Purchases.collectDeviceIdentifiers();
    let attribution = await BranchIo.getFirstReferringParams();
    if(attribution && attribution != null && attribution != undefined)
    {
      let channel = attribution["~channel"];
      let campaign = attribution["~campaign"];
      console.log("attrib" + attribution["~channel"] + " " + attribution["~campaign"]);
      //console.log("BRANCH FIRST REFERRER" + channel + " " + campaign);
      await Purchases.setMediaSource({mediaSource: channel});
      await Purchases.setCampaign({campaign: campaign});
      await Purchases.setCreative({creative: attribution["+referrer"]});
      await this.MPSetSuperProps(
        {
        "network": channel,
        "campaign": campaign}
      );
    }

    /*let attribution = await this.adjust.getAttribution();
    //console.log("ATTRIBUTION " + JSON.stringify(attribution))
    Purchases.setMediaSource({mediaSource: attribution.network});
    Purchases.setCampaign({campaign: attribution.campaign})
    Purchases.setAdGroup({adGroup: attribution.adgroup});
    await this.MPSetSuperProps(
      {"adjustID": await this.adjust.getAdid(),
      "network": attribution.network,
      "campaign": attribution.campaign}
    );
    Purchases.setPushToken({pushToken: localStorage.getItem("pushToken")});*/
  }
}
