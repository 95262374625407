import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Partner } from './partner';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ProService {

  constructor(private httpClient: HttpClient, public userService: UserService) { }

    /**
     * Checks if the code is valid and adds it to the user.
     * @param code to check for.
     * @returns boolean if a partner with the given code exists.
    */
    public async redeemPartnerCode(code: string): Promise<boolean> 
    {
      if(code != undefined && code != "")
      {
        if(await this.getPartner(code))
        {
          return await this.httpClient.get<boolean>(`pro/redeem/partner/${code}`).toPromise();
        }
      }
      return false;
    }

    /**
     * 
     * @param code to check
     * @param school? to check if school has pro
     * @returns if code exists (true, false)
     */
    public async checkPro(school_id?: string): Promise<boolean>
    {
      if(school_id)
      {
        return await this.httpClient.get<boolean>(`pro/check/school/${school_id}`).toPromise();
      }
      return await this.httpClient.get<boolean>(`pro/check`).toPromise();
    }

    /**
     * 
     * @param code to check
     * @param uid to update code to
     * @returns if code exists (true, false)
     */
    public async redeemProCode(code: string): Promise<boolean> 
    {
      if(code != undefined && code != "")
      {
        return await this.httpClient.get<boolean>(`pro/redeem/${code}`).toPromise();
      }
      return false;
    }

    /**
     * Returns a partner, if code is valid.
     * @param code to find the associated partner.
     * @returns partner if one with given code exists.
    */
    public async getPartner(code: string): Promise<Partner | null> 
    {
      if(code != undefined && code != "")
      {
        return await this.httpClient.get<Partner>(`pro/partner/${code}`).toPromise();
      }
      return null;
    }
}