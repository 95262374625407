import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { StatusBar } from "@awesome-cordova-plugins/status-bar/ngx";
import { Platform } from "@ionic/angular";
import { AnalyticsService } from "./shared/analytics.service";
import { LangLevelPipe } from "./shared/pipes/lang-level.pipe";
import { PushService } from "./shared/push.service";
import { environment } from "../environments/environment";
import firebase from "firebase/compat/app"; 
//import { PushService } from "./shared/push.service";
import { App } from '@capacitor/app';
import { AuthService } from "./shared/auth.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  providers: [ LangLevelPipe ]
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public analytics: AnalyticsService,
    public readonly router: Router,
    public pushService: PushService,
    public authService: AuthService
  ) {
    this.initializeFirebase();
    this.initializeApp();
  }

  async initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      await this.browserActions();
      await this.pushService.init();
      if(this.platform.is("android"))
      {
        this.statusBar.overlaysWebView(true);
      }
      await this.analytics.init();
      this.splashScreen.hide();
    });
  }

  async browserActions() {
    App.addListener('appUrlOpen', (data: any) => {
      if (data && data.url) {
        const url = new URL(data.url);
        if (url.protocol === 'grammario:') {
          console.log('Handling grammario deeplink');
          const code = url.searchParams.get('code');
          const state = url.searchParams.get('state');
          this.authService.handleAuthCallback(code, state);
        }
      }
    });
  }

  public async initializeFirebase(): Promise<void> {
    if (this.platform.is('capacitor')) {
      return;
    }
    firebase.initializeApp(environment.firebaseConfig);
  }
}
