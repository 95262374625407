import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {
  Exercise,
  ExerciseQuestionType,
} from "./exercises.interface";
import { User } from "./user";
import { UserService } from "./user.service";
import { AuthService } from "./auth.service";
import { ExerciseService } from "./exercise.service";
import { Router } from "@angular/router";
import { PushService } from "./push.service";
import { AnalyticsService } from "./analytics.service";
//import { WorkoutPage } from "../pages/workout/workout.page";

/**
 * Service that holds the exercises.
 */
@Injectable({
  providedIn: "root",
})
export class WorkoutService {
  public exerciseQuestions: Array<ExerciseQuestionType> = [];
  public tags: Array<string> = [];
  public activeExercise?: Exercise;
  public isInitialized = new BehaviorSubject(false);
  public workoutArray: Array<string> = [];
  public userTopics: any;
  public userTimeGoal: number = 5;
  public possibleTasks: any = [];
  public user: User;
  public allExercises: Exercise[] = [];
  public workoutObject: any;
  public workoutState$ = new BehaviorSubject<boolean>(false);
  public addedIndex = [];

  public constructor
  (
    public userService: UserService,
    public authService: AuthService,
    public exerciseService: ExerciseService,
    public router: Router,
    public pushService: PushService,
    public analyticsService: AnalyticsService
  ) 
    {
      Promise.all([this.getWorkout(), this.getAllExercises()])
      .then(() => {
        this.isInitialized.next(true);
      });
    }

  public async createWorkout(time: number, topics: Array<string>) {
    //Clear objects
    this.workoutArray = [];
    this.addedIndex = [];
    this.possibleTasks = [];
    let user = await this.getUser();
    if(user.workout.exercises != undefined && user.workout.exercises.length > 0)
    {
      //Clear exercises obj
      user.workout.exercises = [];
    }
    if(topics != undefined && topics.length > 0)
    {
      if(user.workout.topics == undefined || user.workout.topics.length == 0)
      {
        user.workout.topics = topics;
        await this.userService.updateUser(user);
      }
      switch (time) {
        //Two exercises
        case 5:
          for (let i = 0; i < topics.length; i++){
            await this.getExercisesByTag(topics[i]);
          }
          for (let i = 0; i<3; i++){
            this.pushRandTask();
          }
          await this.pushWorkout(this.workoutArray);
          break;
        //Four exercises
        case 10:
          for (let i = 0; i < topics.length; i++){
            await this.getExercisesByTag(topics[i]);
          }
          for (let i = 0; i<4; i++){
            this.pushRandTask();
          }
          await this.pushWorkout(this.workoutArray);
          break;
        //Six exercises
        case 15:
          for (let i = 0; i < topics.length; i++){
            await this.getExercisesByTag(topics[i]);
          }
          for (let i = 0; i<6; i++){
            this.pushRandTask();
          }
          await this.pushWorkout(this.workoutArray);
          break;
        //Eight exercises
        case 20:
          for (let i = 0; i < topics.length; i++){
            await this.getExercisesByTag(topics[i]);
          }
          for (let i = 0; i<8; i++){
            this.pushRandTask();
          }
          await this.pushWorkout(this.workoutArray);
          break;
        //Ten exercises
        case 30:
          this.user = await this.userService.getUserApi();
          let updatedOnboarding = this.user.onboarding;
          updatedOnboarding.dailyGoal = 20;
          let user = {uid: this.user.uid, onboarding: updatedOnboarding}
          await this.userService.updateUser(user)
          break;
        default:
          return;
      }
    }
  }

  public pushRandTask() {
    if(this.possibleTasks.length < this.workoutArray.length)
    {
      this.addedIndex = [];
    }
      let rand = Math.floor(Math.random() * this.possibleTasks.length) + 0;
      while(this.addedIndex.includes(rand))
      {
        rand = Math.floor(Math.random() * this.possibleTasks.length) + 0;
      }
      this.addedIndex.push(rand);
      let selectedTask = this.possibleTasks[rand];
      if(selectedTask != undefined && !this.workoutArray.includes(selectedTask))
      {
        this.workoutArray.push(selectedTask);
      }
      return selectedTask;
  }

  public async getAllExercises()
  {
    this.allExercises = await this.exerciseService.returnAllExercises();
  }

  public async getExercisesByTag(tag: string){
    if(this.allExercises == undefined || this.allExercises.length == 0)
    {
      await this.getAllExercises();
    }
    for (let i = 0; i < this.allExercises.length; i++){
      if (this.allExercises[i] != undefined && this.allExercises[i].tags.includes(tag) && !this.allExercises[i].tags.includes("noWorkout"))
      {
        if(!this.possibleTasks.includes(this.allExercises[i]._id))
        {
          this.possibleTasks.push(this.allExercises[i]._id);
        }
      }
    }
  }

  public async pushTopics(chosenTopics: Array<string>) {
    this.user = await this.userService.getUserApi();
    let workoutObject = {
      exercises: [],
      topics: chosenTopics,
      exercisesDone: []
    }
    this.user.workout = workoutObject;
    await this.userService.updateUser(this.user);
    await this.analyticsService.trackEvent("Set Workout Topics", {"topics": chosenTopics});
    await this.startWorkout();
    this.workoutState$.next(false);
  }

  public async pushWorkout(exerciseArray: Array<string>) {
    this.user = await this.userService.getUserApi();
    if(this.user.workout != undefined || this.user.workout.exercises.length == 0)
    {
      this.workoutObject =
      {
        exercises: exerciseArray,
        topics: this.user.workout.topics,
        exercisesDone: []
      }
      this.user.workout = this.workoutObject;
      await this.userService.updateUser(this.user);
    }
  }

  public async startWorkout() {
    let user = await this.getUser();
    await this.createWorkout(user.onboarding.dailyGoal, user.workout.topics);
    //Save workout creation time, set Daily Push Notification
    let currentDate = new Date();
    localStorage.setItem("workoutCreated", currentDate.toISOString());
    await this.analyticsService.trackEvent("Workout Created");
    let workoutDate = new Date(localStorage.getItem("workoutCreated"));
    workoutDate = new Date(workoutDate.getTime() + 1000 * 60 * 60 * 24);
    workoutDate.setHours(12);
    workoutDate.setMinutes(0);
    workoutDate.setSeconds(0);
    await this.pushService.scheduleLocal(
    {
      id: 1, title: "Tägliches Lern-Workout", 
      text: 'Dein persönliches Übungspaket ist bereit für Dich!',
      foreground: true,
      data: {action: "workout"},
      trigger: { at: workoutDate },
    });
    this.workoutState$.next(true);
    localStorage.setItem("workoutMode", "true");
  }

  public async finishExercise(id: string)
  {
    this.user = await this.getUser();
    if(this.user.workout.exercises.includes(id) && !this.user.workout.exercisesDone.includes(id))
    {
      this.user.workout.exercisesDone.push(id);
      await this.userService.updateUser(this.user);
    }
  }

  public async getUser(): Promise<User> {
    return await this.userService.getUserApi();
  }

  public async getWorkout(): Promise<any> | undefined {
    let user = await this.getUser();
    this.workoutObject = user.workout;
    if(!user.workout)
    {
      return undefined;
    }
    return user.workout;
  }

  public getWorkoutState()
  {
    return this.workoutState$.asObservable();
  }

  public async getProgress(user: User): Promise<number>
  {
    if(user && user.workout != undefined && user.workout.exercises.length > 0 && user.workout.exercisesDone != undefined)
    {
      let exercisesDone = user.workout.exercisesDone;
      return Math.round((exercisesDone.length / user.workout.exercises.length) * 100);
    }
    return 0;
  }

  public getWorkoutExpire()
  {
    let workoutDate = new Date(localStorage.getItem("workoutCreated"));
    workoutDate = new Date(workoutDate.getTime() + 1000 * 60 * 60 * 24);
    workoutDate.setHours(12);
    workoutDate.setMinutes(0);
    workoutDate.setSeconds(0);
    return workoutDate;
  }

  public async checkExpire()
  {
    if(localStorage.getItem("workoutCreated") != null)
    {
      let workoutDate = new Date(localStorage.getItem("workoutCreated"));
      workoutDate = new Date(workoutDate.getTime() + 1000 * 60 * 60 * 24);
      workoutDate.setHours(12);
      workoutDate.setMinutes(0);
      workoutDate.setSeconds(0);
      let currentDate = new Date();
      
      //let diff = currentDate.getTime() - workoutDate.getTime();
      //let hours = diff / (1000 * 60 * 60);
      if(currentDate >= workoutDate)
      {
        //If workout is expired, renew it
        await this.startWorkout();
        await this.analyticsService.trackEvent("Workout Renewed");
      }
    }
  }
}
