import { NgModule } from "@angular/core";
import { canActivate, redirectUnauthorizedTo } from "@angular/fire/compat/auth-guard";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["login"]);

const routes: Routes = [
  {
    path: "",
    redirectTo: "start",
    pathMatch: "full"
  },
  {
    path: "start",
    loadChildren: () => import("./pages/authentication/start/start.module").then(m => m.StartPageModule),
  },
  {
    path: "register",
    loadChildren: () => import("./pages/authentication/register/register.module").then(m => m.RegisterPageModule)
  },
  {
    path: "register/anon",
    loadChildren: () => import("./pages/authentication/register/register.module").then( m => m.RegisterPageModule),
  },
  {
    path: "username",
    loadChildren: () => import("./pages/authentication/set-username/set-username.module").then(m => m.UsernamePageModule)
  },
  {
    path: "login",
    loadChildren: () => import("./pages/authentication/login/login.module").then(m => m.LoginPageModule),
  },
  {
    path: "verifyMail",
    loadChildren: () => import("./pages/authentication/verify-mail/verify-mail.module").then(m => m.VerifyMailPageModule)
  },
  {
    path: "tabs",
    loadChildren: () => import("./tabs/tabs.module").then(m => m.TabsPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: "home",
    loadChildren: () => import("./pages/home/home.module").then(m => m.HomePageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: "scoreboard",
    loadChildren: () => import("./pages/scoreboard/scoreboard.module").then( m => m.ScoreboardPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: "profile",
    loadChildren: () => import("./pages/profile/profile.module").then( m => m.ProfilePageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: "exercise/:id",
    loadChildren: () => import("./pages/exercise/exercise.module").then( m => m.ExercisePageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: "creation",
    loadChildren: () => import("./pages/creation/creation.module").then(m => m.CreationPageModule),
    // TODO only authorize admins
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: "edit/exercise/:exerciseId",
    loadChildren: () => import("./pages/creation/creation.module").then(m => m.CreationPageModule),
    // TODO only authorize admins
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: "edit/question/:questionId",
    loadChildren: () => import("./pages/creation/creation.module").then(m => m.CreationPageModule),
    // TODO only authorize admins
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: "edit/task/:taskId",
    loadChildren: () => import("./pages/tasks/tasks.module").then(m => m.TasksPageModule),
    // TODO only authorize admins
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: "results",
    loadChildren: () => import("./pages/results/results.module").then( m => m.ResultsPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'tasks',
    loadChildren: () => import('./pages/tasks/tasks.module').then( m => m.TasksPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'nolifes',
    loadChildren: () => import('./pages/monetize/nolifes/nolifes.module').then( m => m.NolifesPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./pages/monetize/subscription/subscription.module').then( m => m.SubscriptionPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'subscription/nohearts',
    loadChildren: () => import('./pages/monetize/subscription/subscription.module').then( m => m.SubscriptionPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'subscription/purchase',
    loadChildren: () => import('./pages/monetize/subscription/subscription.module').then( m => m.SubscriptionPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: "promo/purchase",
    loadChildren: () => import('./pages/monetize/promo/promo.module').then( m => m.PromoPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'shop',
    loadChildren: () => import('./pages/shop/shop.module').then( m => m.ShopPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'credits',
    loadChildren: () => import('./pages/credits/credits.module').then( m => m.CreditsPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/authentication/password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: "promo",
    loadChildren: () => import("./pages/monetize/promo/promo.module").then( m => m.PromoPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: "pro",
    loadChildren: () => import("./pages/pro/pro.module").then( m => m.ProPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'info',
    loadChildren: () => import('./pages/info/info.module').then( m => m.InfoPageModule),
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./pages/edit-profile/edit-profile.module').then( m => m.EditProfilePageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'anamnese',
    loadChildren: () => import('./pages/onboarding/anamnese/anamnese.module').then( m => m.AnamnesePageModule),
  },
  {
    path: 'pre-intro',
    loadChildren: () => import('./pages/onboarding/pre-intro/pre-intro.module').then( m => m.PreIntroPageModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./pages/onboarding/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'provider-selection',
    loadChildren: () => import('./pages/onboarding/provider-selection/provider-selection.module').then( m => m.ProviderSelectionPageModule)
  },
  {
    path: 'profile-prompt',
    loadChildren: () => import('./pages/onboarding/profile-prompt/profile-prompt.module').then( m => m.ProfilePromptPageModule)
  },
  {
    path: 'offer',
    loadChildren: () => import('./pages/onboarding/offer/offer.module').then( m => m.OfferPageModule)
  },
  {
    path: 'anonym-disadvantages',
    loadChildren: () => import('./pages/onboarding/anonym-disadvantages/anonym-disadvantages.module').then( m => m.AnonymDisadvantagesPageModule)
  },
  {
    path: 'registration-options',
    loadChildren: () => import('./pages/onboarding/registration-options/registration-options.module').then( m => m.RegistrationOptionsPageModule)
  },
  {
    path: "login/reauth",
    loadChildren: () => import("./pages/authentication/login/login.module").then( m => m.LoginPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: "nolifes/anon",
    loadChildren: () => import("./pages/monetize/nolifes/nolifes.module").then( m => m.NolifesPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'workout',
    loadChildren: () => import('./pages/workout/workout.module').then( m => m.WorkoutPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'show-topic/:topic',
    loadChildren: () => import('./pages/show-topic/show-topic.module').then( m => m.ShowTopicPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'preview/:exerciseId',
    loadChildren: () => import('./preview/preview.module').then( m => m.PreviewPageModule),
    ...canActivate(redirectUnauthorizedToLogin)
  },
  {
    path: 'explain/:topic',
    loadChildren: () => import('./pages/explanation/explanation.module').then( m => m.ExplanationPageModule)
  },
  {
    path: 'influencer',
    loadChildren: () => import('./pages/influencer/influencer.module').then( m => m.InfluencerPageModule)
  },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
