import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ToastController } from "@ionic/angular";
import { from, Observable } from "rxjs";

import { environment } from "../../environments/environment";
import { AuthService } from "../shared/auth.service";
import firebase from "firebase/compat/app"; 
import "firebase/compat/auth" 
import { switchMap } from "rxjs/internal/operators/switchMap";

@Injectable()
export class HTTPInterceptor implements HttpInterceptor {
    private authToken: any;
    public fireUser?: firebase.User;

    constructor(public toastController: ToastController,
        public authService: AuthService,
        public readonly auth: AngularFireAuth) {
          this.auth.authState.subscribe(user => {
            if (user) {
                this.fireUser = user;
            } else {
                console.error("No user signed in.");
            }
        });
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.retrieveToken()).pipe(
            switchMap(token => {
                let apiReq = req.clone({ url: `${environment.baseUrl}/${req.url}` });

                if (token) {
                    apiReq = apiReq.clone({
                        headers: apiReq.headers.set("Authorization", `Bearer ${token}`)
                    });
                }
                
                return next.handle(apiReq);
            })
        );
    }

    private async retrieveToken(): Promise<string | null> {
        if (this.fireUser) {
            try {
                this.authToken = await this.fireUser.getIdToken();
                return this.authToken;
            } catch (error) {
                console.error("Error retrieving ID token:", error);
            }
        } else {
            console.warn("User not signed in or token unavailable.");
        }
        return null;
    }
}