import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { Onboarding } from './onboarding';
import { User } from './user';

@Injectable()
export class OnboardingService {
  private _storage: Storage | null = null;

  constructor(
    private storage: Storage,
    public router: Router,
    ) {
      this.init();
    }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  public async saveOnboarding(obj: Onboarding)
  {
    return await this._storage?.set("onboarding", obj);
  }

  public async getOnboardingData()
  {
    return await this._storage?.get("onboarding");
  }

  public async deleteOnboarding()
  {
    return await this._storage?.remove("onboarding");
  }

  public async checkUser(user: User)
  {
    //Check if existing user has already finished Onboarding
    if(user && !user.onboarding)
    {
      this.router.navigate(["anamnese"], { replaceUrl: true });
    }
  }
}
